import React from 'react';
import * as S from './styled';
import {
    BrowserView,
    MobileView,
    isBrowser,
    isMobile,
} from 'react-device-detect';

const Introduce = () => {
    return (
        <div>
            <h1>사람들 소개입니다.</h1>
            <h1>사람들 소개입니다.</h1>
            <h1>사람들 소개입니다.</h1>
            <h1>사람들 소개입니다.</h1>
            <h1>사람들 소개입니다.</h1>
            <h1>사람들 소개입니다.</h1>
        </div>
    );
};

export default Introduce;
