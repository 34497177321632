import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Main from './main';
import Navigation from '../components/Navigation';
import Introduce from './introduce';
import People from './people';
export const EntryRoute = () => (
    <BrowserRouter>
        <Navigation />
        <Switch>
            <Route exact path="/" component={Main} />
            <Route path="/introduce" component={Introduce} />
            <Route path="/people" component={People} />
        </Switch>
    </BrowserRouter>
);
