import React, { useState, useRef, useEffect } from 'react';
import { isEmpty } from '../functions';
const Photos = (props) => {
    function shuffle(array) {
        var currentIndex = array.length,
            temporaryValue,
            randomIndex;

        // While there remain elements to shuffle...
        while (0 !== currentIndex) {
            // Pick a remaining element...
            randomIndex = Math.floor(Math.random() * currentIndex);
            currentIndex -= 1;

            // And swap it with the current element.
            temporaryValue = array[currentIndex];
            array[currentIndex] = array[randomIndex];
            array[randomIndex] = temporaryValue;
        }

        return array;
    }
    const data = [
        {
            image:
                'https://firebasestorage.googleapis.com/v0/b/volunteer-9545c.appspot.com/o/img%2F1.jpg?alt=media&token=5f1021de-5baf-49b3-b140-c5dae134a3fd',
            comment: '1',
        },
        {
            image:
                'https://firebasestorage.googleapis.com/v0/b/volunteer-9545c.appspot.com/o/img%2F4.jpg?alt=media&token=8e3f41c9-df49-4d03-bbe4-1bb9242f937b',
            comment: '2',
        },
        {
            image:
                'https://firebasestorage.googleapis.com/v0/b/volunteer-9545c.appspot.com/o/img%2F5.jpg?alt=media&token=5b7c5a69-99dd-4708-8676-31afe9930876',
            comment: '3',
        },
        {
            image:
                'https://firebasestorage.googleapis.com/v0/b/volunteer-9545c.appspot.com/o/img%2F1.jpg?alt=media&token=5f1021de-5baf-49b3-b140-c5dae134a3fd',
            comment: '4',
        },
        {
            image:
                'https://firebasestorage.googleapis.com/v0/b/volunteer-9545c.appspot.com/o/img%2F4.jpg?alt=media&token=8e3f41c9-df49-4d03-bbe4-1bb9242f937b',
            comment: '5',
        },
        {
            image:
                'https://firebasestorage.googleapis.com/v0/b/volunteer-9545c.appspot.com/o/img%2F5.jpg?alt=media&token=5b7c5a69-99dd-4708-8676-31afe9930876',
            comment: '6',
        },
        {
            image:
                'https://firebasestorage.googleapis.com/v0/b/volunteer-9545c.appspot.com/o/img%2F1.jpg?alt=media&token=5f1021de-5baf-49b3-b140-c5dae134a3fd',
            comment: '7',
        },
        {
            image:
                'https://firebasestorage.googleapis.com/v0/b/volunteer-9545c.appspot.com/o/img%2F4.jpg?alt=media&token=8e3f41c9-df49-4d03-bbe4-1bb9242f937b',
            comment: '8',
        },
    ];
    return (
        <>
            <section className="cards">
                <ul className="cards__list">
                    {!isEmpty(data) &&
                        data.map((content, i) => {
                            if (i < 8)
                                return (
                                    <li
                                        id={`slide-card-${i + 1}`}
                                        key={i}
                                        className="card"
                                        style={{
                                            backgroundImage: `url(${content.image})`,
                                            backgroundPosition: 'center',
                                            backgroundSize: '140%',
                                            backgroundRepeat: 'no-repeat',
                                            width: '100%',
                                            overflow: 'hidden',
                                        }}
                                    >
                                        <a
                                            href={content.image}
                                            className="card-link"
                                        >
                                            <div className="card-title">
                                                {/* <span className="card-title-comment">{`${content.comment}`}</span> */}
                                                {/* <span className="card-title-name">{`${content.name}`}</span> */}
                                            </div>
                                        </a>
                                    </li>
                                );
                        })}
                </ul>
            </section>
        </>
    );
};

export default Photos;
