import React from 'react';

const Footer = () => {
    return (
        <div className="footer-wrap">
            <div className="footer">
                <div className="footer-title">보건의료통합봉사회</div>
                <div className="footer-description">
                    대표: 박다솔 | 서울 서초구 서초대로 74길 4 삼성생명서초타워
                    27층
                </div>
                <div className="footer-description">
                    문의: contact@volunteer.com
                </div>
            </div>
        </div>
    );
};

export default Footer;
