import React from 'react';

const MainMessage = (props) => {
    const { message, description, isMobile } = props;
    return (
        <>
            {isMobile ? (
                <div>
                    <div className="mainMessage-mobile">{message}</div>
                    <div className="mainDescription-mobile">
                        {description.split('/').map((line) => {
                            console.log('dd');
                            return (
                                <span>
                                    {line}
                                    <br />
                                </span>
                            );
                        })}
                    </div>
                </div>
            ) : (
                <div>
                    <div className="mainMessage">{message}</div>
                    <div className="mainDescription">
                        {description.split('/').map((line) => {
                            console.log('dd');
                            return (
                                <span>
                                    {line}
                                    <br />
                                </span>
                            );
                        })}
                    </div>
                </div>
            )}
        </>
    );
};

export default MainMessage;
