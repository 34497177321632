import React, { useEffect, useRef, useState } from 'react';
import {
    BrowserView,
    MobileView,
    isBrowser,
    isMobile,
} from 'react-device-detect';
import { Link } from 'react-router-dom';

const Navigation = () => {
    const [isClicked, setIsClicked] = useState(false);
    const [scrollZero, setScrollZero] = useState(true);
    const [goingUp, setGoingUp] = useState(true);
    const prevScrollY = useRef(0);
    const handleScroll = () => {
        const currentScrollY = window.scrollY;
        if (currentScrollY != 0) {
            setScrollZero(false);
        } else {
            setScrollZero(true);
        }
        if (currentScrollY < 20) {
            setGoingUp(true);
            prevScrollY.current = currentScrollY;
            return;
        }
        if (currentScrollY > 120) {
            if (prevScrollY.current < currentScrollY && goingUp) {
                setGoingUp(false);
            }
            if (prevScrollY.current > currentScrollY + 10 && !goingUp) {
                setGoingUp(true);
            }
            prevScrollY.current = currentScrollY;
            return;
        }
    };
    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [goingUp]);
    return (
        <div
            className="navigation-wrap"
            style={{
                //  display: !goingUp && 'none'
                opacity: !goingUp && '0',
                zIndex: '1000',
            }}
        >
            <div className="navigation">
                <img
                    className="navigation-logo"
                    src="https://firebasestorage.googleapis.com/v0/b/volunteer-9545c.appspot.com/o/img%2Fmain_logo.png?alt=media&token=586dd718-0239-4e60-acc9-4153ba13672c"
                    alt="메인 로고"
                />
                {!isMobile ? (
                    <div className="navigation-menu">
                        <Link to="/" style={{ textDecoration: 'none' }}>
                            <span className="navigation-menu-item">홈</span>
                        </Link>
                        <Link
                            to="/introduce"
                            style={{ textDecoration: 'none' }}
                        >
                            <span className="navigation-menu-item">
                                단체소개
                            </span>
                        </Link>
                        <Link to="/people" style={{ textDecoration: 'none' }}>
                            <span className="navigation-menu-item">사람들</span>
                        </Link>
                        <a
                            href="https://blog.naver.com/ihco_2019"
                            style={{ textDecoration: 'none' }}
                        >
                            <span className="navigation-menu-item">블로그</span>
                        </a>
                    </div>
                ) : (
                    <div className="navigation-menu-icon-wrap">
                        {isClicked ? (
                            <img
                                className="navigation-menu-icon"
                                src="/cancel.png"
                                alt="메뉴 아이콘"
                                onClick={() => setIsClicked(!isClicked)}
                            />
                        ) : (
                            <img
                                className="navigation-menu-icon"
                                src="/menu.png"
                                alt="메뉴 아이콘"
                                onClick={() => setIsClicked(!isClicked)}
                            />
                        )}
                    </div>
                )}
            </div>

            <div
                className={`navigation-menu-mobile ${
                    isClicked ? 'menu-clicked' : 'menu-noclicked'
                }`}
            >
                {isClicked && (
                    <>
                        <Link to="/" style={{ textDecoration: 'none' }}>
                            <div className="navigation-menu-mobile-item">
                                홈
                            </div>
                        </Link>
                        <Link
                            to="/introduce"
                            style={{ textDecoration: 'none' }}
                        >
                            <div className="navigation-menu-mobile-item">
                                단체소개
                            </div>
                        </Link>
                        <Link to="/people" style={{ textDecoration: 'none' }}>
                            <div className="navigation-menu-mobile-item">
                                사람들
                            </div>
                        </Link>
                        <a
                            href="https://blog.naver.com/ihco_2019"
                            style={{ textDecoration: 'none' }}
                        >
                            <div className="navigation-menu-mobile-item">
                                블로그
                            </div>
                        </a>
                    </>
                )}
            </div>
        </div>
    );
};

export default Navigation;
