import React from 'react';
import Footer from '../../components/Footer';
import MainMessage from '../../components/MainMessage';
import Photos from '../../components/Photos';
import * as S from './styled';
import {
    BrowserView,
    MobileView,
    isBrowser,
    isMobile,
} from 'react-device-detect';
import News from '../../components/News';
const Main = () => {
    return (
        <div>
            {isMobile ? (
                <div className="main-mobile">
                    <div className="main-mobile-wrap">
                        <div className="main-mobile-title">
                            <span>세대 통합과 사회의 국민복리 증진</span>
                        </div>
                        <div className="main-mobile-description">
                            <span>
                                보건의료통합봉사단은 다양한 전공분야의 청년
                                대학생들이 모여 열정과 능력을 바탕으로{'\n'}
                                보건의료계의 세대 통합과 사회의 국민복리 증진을
                                위한다는 뜻을 가진 재능기부 전문 봉사단입니다.
                            </span>
                        </div>
                        <div className="main-btn">지원하러 가기</div>
                    </div>
                </div>
            ) : (
                <div className="main">
                    <div className="main-wrap">
                        <div className="main-title">
                            <span>세대 통합과 사회의 국민복리 증진</span>
                        </div>
                        <div className="main-description">
                            <span>
                                보건의료통합봉사단은 다양한 전공분야의 청년
                                대학생들이 모여 열정과 능력을 바탕으로{'\n'}
                                보건의료계의 세대 통합과 사회의 국민복리 증진을
                                위한다는 뜻을 가진 재능기부 전문 봉사단입니다.
                            </span>
                        </div>
                        <div className="main-btn">지원하러 가기</div>
                    </div>
                </div>
            )}

            {isMobile ? (
                <MainMessage
                    message='"저의 선택에 따라서 한 명의 인생이 바뀔 수 있잖아요"'
                    description={
                        '당신의 한 번의 도전이, 당신의 한 번의 선택이 가자고 어서요/에하 그렇다 이말 한 명, 아니 열 명, 아니 백 명/수 많은 생명을 살립니다 맞나 틀리나./나는 계속 글을 쓸거다 계속 쓸거임 오케이요'
                    }
                    isMobile={true}
                />
            ) : (
                <MainMessage
                    message='"저의 선택에 따라서 한 명의 인생이 바뀔 수 있잖아요"'
                    description={
                        '당신의 한 번의 도전이, 당신의 한 번의 선택이 가자고 어서요/에하 그렇다 이말 한 명, 아니 열 명, 아니 백 명/수 많은 생명을 살립니다 맞나 틀리나./나는 계속 글을 쓸거다 계속 쓸거임 오케이요'
                    }
                    isMobile={false}
                />
            )}
            <Photos />
            <News />
            <Footer />
        </div>
    );
};

export default Main;
