import styled from 'styled-components';

export const MainWrapper = styled.div`
    width:100%:
    height: 100%;
    background-color:black;
    color:white;
`;
export const MainImgWrapper = styled.div`
    width: 100%;
    text-align: center;
    font-family: anssang;
`;
export const MainImg = styled.img`
    position: relative;
`;

export const MainInput = styled.input`
    position: absolute;
    height: 50px;
    font-family: anssang;
    font-size: 50px;
    top: 180px;
    right: 50px;
    width: 100px;
    border: none;
    background: none;
    color: white;
`;

export const StyledButton = styled.button`
    width: 200px;
    height: 200px;
    border-radius: 0.25rem;
    font-size: 1rem;
    line-height: 1.5;
    border: 1px solid lightgray;
    color: gray;
    backgroud: white;
`;
