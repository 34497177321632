import React from 'react';
import * as S from './styled';
import {
    BrowserView,
    MobileView,
    isBrowser,
    isMobile,
} from 'react-device-detect';

const People = () => {
    return <div>사람들 소개입니다.</div>;
};

export default People;
